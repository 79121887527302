




































































import Vue from 'vue';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { RoleAccordCadre } from '@/models';
import { DataTableHeader } from '@/shared/models';
import AccordsCadresRoleForm from '@/components/AccordsCadres/AccordsCadresRoleForm.vue';
import AccordsCadresSocieteForm from '@/components/AccordsCadres/AccordsCadresSocieteForm.vue';
import { AccordsCadresStoreMethods } from '../../store/modules/accordscadres/AccordsCadresStore';
import { Getter, Action } from 'vuex-class';
import AccordsCadres from '../../views/Administration/AccordsCadres/AccordsCadres';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Profils } from '@/shared/enums';
import Confirm from '@/components/Confirm.vue';

@Component({
    name: 'AccordsCadresSocieteTable',
    components: { AccordsCadresRoleForm, AccordsCadresSocieteForm, Confirm },
})
export default class AccordsCadresSocieteTable extends Mixins(GrilleMixin) {

    private dialog = false;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    @Prop({ default: () => new Array<RoleAccordCadre>() })
    private roleAccordCadre!: RoleAccordCadre[];

    private clearMembres = false;

    @Getter(AccordsCadresStoreMethods.ACCORD_CADRE)
    private accordCadre: AccordsCadres;

    @Action(AccordsCadresStoreMethods.PERSISTER_AJOUT_MEMBRES_SELECTIONNEES)
    public persistAjoutMembres: () => Promise<AccordsCadres>;

    @Action(AccordsCadresStoreMethods.SUPPRIMER_MEMBRE)
    public supprimerMembre: (payload: { societeId: number }) => Promise<AccordsCadres>;

    @Action(AccordsCadresStoreMethods.MODIFIER_ROLE_MEMBRE)
    public modifierRoleMembre: () => void;

    private columns: DataTableHeader[] = [
        { text: 'Raison sociale' },
        { text: 'SIRET' },
        { text: 'Créé par' },
        { text: 'Profil' },
        { text: '' },
    ];

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };

    private suppressionSociete(id: number) {
        this.$refs.confirm.open(
            'Suppression d\'un membre', 
            'Confirmez-vous la suppression du membre de l\'accord cadre ?', 
            { color: 'blue', width: 1000, zIndex: 200 }
        ).then((result) => {
            if (result) {
                this.supprimerMembre({ societeId: id });
            }
        });
    }
}

