



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ValidationAction',
})
export default class ValidationAction extends Vue {
    @Prop({ default: null })
    private formRef!: HTMLFormElement;

    @Prop({ default: '' })
    private tab!: string;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private loading = false;

    public clear(): void {
        this.$emit('onReset' + this.tab);
    }
    public resetValidation(): void {
        this.$emit('onResetValidation' + this.tab);
    }
    public submit(): void {
        this.$emit('onSubmit' + this.tab);
    }
}
