





























































import { DataTableHeader } from '@/shared/models';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Mixins, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import {AccordCadre, Societe, AccordsCadresSociete, DroitsAccordsCadres, Utilisateur} from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { ArrayHelper } from '@/shared/helpers/ArrayHelper.ts';

@Component({
    name: 'AccordsCadresDroits',
})
export default class AccordsCadresDroits extends Mixins(GrilleMixin) {

    // Store getter pour l'accord cadre.
    @Getter(AccordsCadresStoreMethods.ACCORD_CADRE)
    private accordCadre: AccordCadre;

    // Sociétés affilié séléctionnées.
    private societesSelectionees: Map<number, Array<number>> = new Map();

    // Colonnes.
    private columns: DataTableHeader[] = [
        { text: 'Propriétaires des sociétés membres' },
        { text: 'Droits' },
        { text: 'Action' },
    ];

    private loading: boolean = false;

    // Getter Fournisseurs de sociétés et accord-cadre courant pour v-data-table.
    public get fournisseurUniqueUtilisateur(): any[] {
        const societesToPush: any[] = new Array<any>();
        // Map des valeurs accord cadre société à un format avec un key
        const values = ArrayHelper.remove_duplicate<Utilisateur & {key: number}>(this.accordCadre.accordCadreSocietes
                .map((item: AccordsCadresSociete, index: number) => ({
                    key: item.societe.utilisateur.id,
                    ...item.societe.utilisateur
                }) as Utilisateur & { key: number}));
        // Boucle sur les sociétés.
        values.forEach((v: Utilisateur& { key: number}) => {
            // Vider le tableau.
            societesToPush.splice(0, societesToPush.length);
            // Boucle sur les droits accord-cadre.
            this.accordCadre.droitsAccordsCadres.forEach((d: DroitsAccordsCadres) => {
                if(v.key === d.utilisateurId) {
                    societesToPush.push(d.societeAffilieeId);
                }
            });
            this.societesSelectionees.set(v.key, [...societesToPush]);
        });
        return values;
    }

    // Getter sociétés pour le select.
    public societes(key: number): Societe[] {
        return this.accordCadre.accordCadreSocietes
                .map((item: AccordsCadresSociete, index: number) => ({
                    disabled: item.societe.utilisateur.id === key,
                    ...item.societe}));
    }


    // Définiton des sociétés séléctionnées.
    public setSocietesSelectionnees(id:number, value: any) {
        this.societesSelectionees.set(id, []);
        this.societesSelectionees.get(id).push(...value);
    }

    // Mise à jour des droits des l'accords cadres.
    public update(key: number) {
        this.loading = true;
        const accordCadreService = new ApiService<DroitsAccordsCadres[]>('/accordCadre/modifierDroits');
        return new Promise<void>((resolve, reject) => {
            accordCadreService.post(this.constructionDroits(key))
            .then((reponse) => {
                resolve();
            }).catch((error: any) => reject(error))
            .finally(() => this.loading = false);
        });
    }

    // Construction des droits accords cadres.
    public constructionDroits(key: number): DroitsAccordsCadres[] {
        return this.societesSelectionees.get(key).map((item) =>
            (
                {
                    accordCadreId: this.accordCadre.id,
                    societeAffilieeId: item,
                    utilisateurId: key
                }
        ));
    }

}
