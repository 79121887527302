import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import template from './EditAccordCadre.Template.vue';
import ContainerEditAccordsCadresForm from '@/components/AccordsCadres/ContainerEditAccordsCadresForm.vue';
import { Action, Mutation } from 'vuex-class';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import { CommonMixin } from '@/shared/mixins';
import { PrescripteurInterne, AccordCadre, Profil, RoleAccordCadre } from '@/models';
import { ApiAutocompleteHelper } from '@/services/ApiAutocompleteHelper';
import { getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle } from '@/shared/models';

@Component({
    ...template,
    name: 'EditAccordCadre',
    components: { ContainerEditAccordsCadresForm },
})
export default class EditAccordCadre extends Mixins(CommonMixin) {

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.RoleAccordCadre))
    private roleAccordCadre: RoleAccordCadre[];

    @Action(AccordsCadresStoreMethods.RECUPERER_ACCORD_CADRE)
    public getAndSetAccordCadreById: (payload: {id: number}) => Promise<AccordCadre>;

    @Action(AccordsCadresStoreMethods.RECUPERER_PROFIL_ACCORD_CADRE)
    public getProfilAccordCadre: () => Promise<Profil>;

    @Mutation(AccordsCadresStoreMethods.RESET_ALL)
    public resetAll: () => void;

    @Prop({default: 0})
    public tab: number;

    public created() {
        this.resetAll();
    }

    public mounted() {
        Promise.all([
            this.getValeursReferentielles(TypeValeurReferentielle.RoleAccordCadre),
            this.getAndSetAccordCadreById({id: parseInt(this.$route.params.id, 10)})
        ]).then((_) => {
            this.getProfilAccordCadre();
        });
    }
    /**
     * Promesse pour l'AutoComplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesPromise(recherche: string): Promise<PrescripteurInterne[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<PrescripteurInterne>(recherche, `/prescripteurInterne/obtenirTous`);
    }

    /**
     * Promesse pour l'autocomplete d'un prescripteur interne.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesUpdatePromise(id: number): Promise<PrescripteurInterne> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<PrescripteurInterne>(id, `/prescripteurInterne/obtenir`);
    }
}
