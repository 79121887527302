










































import { Component, Vue, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { AccordCadre, PrescripteurInterne, RoleAccordCadre, Profil } from '@/models';
import AccordsCadresGeneralForm from '@/components/AccordsCadres/AccordsCadresGeneralForm.vue';
import AccordsCadresSocieteForm from '@/components/AccordsCadres/AccordsCadresSocieteForm.vue';
import AccordsCadresSocieteTable from '@/components/AccordsCadres/AccordsCadresSocieteTable.vue';
import AccordsCadresRoleForm from '@/components/AccordsCadres/AccordsCadresRoleForm.vue';
import AccordsCadresGeneral from '@/components/AccordsCadres/models/AccordsCadresGeneral';
import AccordsCadresDroits from '@/components/AccordsCadres/AccordsCadresDroits.vue';
import ValidationAction from '@/components/ValidationAction.vue';
import { Action, Mutation } from 'vuex-class';
import { AccordsCadresStoreMethods } from '../../store/modules/accordscadres/AccordsCadresStore';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Profils } from '@/shared/enums';
import ApiHelper from '@/services/ApiHelper';

@Component({
    name: 'ContainerEditAccordsCadresForm',
    components: { AccordsCadresGeneralForm,
                  AccordsCadresSocieteForm,
                  AccordsCadresRoleForm,
                  AccordsCadresSocieteTable,
                  AccordsCadresDroits,
                  ValidationAction },
})
export default class ContainerEditAccordsCadresForm extends Vue {
    public $refs!: {
        formGeneralAC: HTMLFormElement;
    };

    private loading = false;

    @Prop({default: 0})
    public tab: number;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    @Prop()
    public prescripteursInternesPromise!: (recherche: string) => Promise<PrescripteurInterne[]>;

    // Prescripteur Interne agissant comme responsable de l'accord-cadre récupéré depuis le composant parent
    @Prop()
    public prescripteursInternesUpdatePromise!: (id: number) => Promise<PrescripteurInterne>;

    @Prop({ default: () => new AccordCadre() })
    private accordCadre!: AccordCadre;

    private accordsCadresGeneral: AccordsCadresGeneral = new AccordsCadresGeneral();

    @Prop({ default: () => new Array<RoleAccordCadre>() })
    private roleAccordCadre!: RoleAccordCadre[];

    @Prop({ default: false })
    private dialog = false;

    @Action(AccordsCadresStoreMethods.MODIFIER_INFOSGENERAL_ACCORD_CADRE)
    public modifierInfosGenerales!: () => Promise<AccordsCadresGeneral>;

    @Mutation(UtilisateurStoreMethods.SET_PROFILS)
    private setProfils: (profils: Profil[]) => void;

    public mounted() {
        ApiHelper.getAll<Profil>('profil/obtenirTous').then((reponse) => {
            this.setProfils(reponse.filter((p) => p.isIntern === false));
        });
    }

    public validerEtModifierInfosGenerales(): void {
        this.loading = true;
        if (this.$refs.formGeneralAC.validate()) {
            this.modifierInfosGenerales().finally(() => {
                this.loading = false;
            });
        } else {
            this.loading = false;
        }
    }

    /**
     * Retour à la page d'administration.
     */
    public annulationEdition(): void {
        (this.$router as VueRouter).push({ name: 'accords-cadres' });
    }

}

