/**
 * Classe d'helper pour les tableau.
 *
 */
export class ArrayHelper<T> {

    /**
     * Merge deux tableau avec suppression des valeurs dupliquées.
     * @param value Valeur du booléen.
     */
    public static merge_array<T>(array1: T[], array2: T[]): T[] {
        const result: T[] = [];
        const mergedArray = array1.concat(array2);
        let len = mergedArray.length;
        let assoc: boolean;

        while (len--) {
            const item = mergedArray[len];
            result.forEach((r) => {
                assoc = JSON.stringify(r) === JSON.stringify(mergedArray[len]);
            });
            if (assoc) {
                result.unshift(item);
            }
        }
        return result;
    }

    /**
     * Suppression des valeurs d'un tableau. (Attention la comparaison est faite sur identifiant)
     *
     * @template T
     * @param {T[]} array
     * @returns {T[]}
     * @memberof ArraynHelper
     */
    public static remove_duplicate<T extends { id: number }>(array: T[]): T[] {
        return [...new Map(array.map(item => [item.id, item])).values()];
    }
}
